import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Flex, Form, Input, message, Modal, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spacer from 'src/components/common/Spacer';
import { useTdsTable } from 'src/components/project/source/TdsTable';
import { updateProjectTds } from 'src/Mutation';
import useGetWarehouseTdss, {
  useGetWarehouseTdssKey
} from 'src/Query/useGetWarehouseTdss';

const fields = ['company_id', 'company_name', 'email'];

const UPDATE_TYPES = {
  CONTACT_NAME: 'Contact Name',
  CONTACT_EMAIL: 'Contact Email'
};

function ReceiveEmailSupplierTable() {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const [form] = Form.useForm();

  const [updateType, setUpdateType] = useState(null);
  const [selectedProjectTds, setSelectedProjectTds] = useState(null);

  const completeFilters = useMemo(
    () => ({
      project: {
        id: projectId,
        funnel_stage: 'queued'
      },
      fields
    }),
    [projectId]
  );
  const queryKey = useGetWarehouseTdssKey(completeFilters);

  const setQueryData = useCallback(
    (fn) => queryClient.setQueryData(queryKey, fn),
    [queryKey]
  );

  const invalidateQueryData = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey
      }),
    [queryKey]
  );

  const { data, isLoading: isLoadingFilteredRecords } = useGetWarehouseTdss(
    completeFilters,
    {
      refetchOnMount: true
    }
  );

  const { mutateAsync: updateProjectTdsMutation, isLoading: updating } =
    useMutation({
      mutationFn: updateProjectTds,
      onSuccess: () => {
        invalidateQueryData();
        onCloseModal();
      },
      onError: () => {
        message.error('Error updating project contact');
      }
    });

  const onSelectProjectTds = (projectTds, type) => {
    setSelectedProjectTds(projectTds);
    setUpdateType(type);
    form.setFieldValue(
      'value',
      type === UPDATE_TYPES.CONTACT_EMAIL
        ? projectTds?.project_contact_email ?? projectTds?.email
        : projectTds?.project_contact_name
    );
  };

  const onCloseModal = () => {
    setSelectedProjectTds(null);
    setUpdateType(null);
  };

  const onUpdateProjectContact = (values) => {
    const isUpdatingEmail = updateType === UPDATE_TYPES.CONTACT_EMAIL;
    const body = {
      id: selectedProjectTds.project_tds_id,
      [isUpdatingEmail ? 'contact_email' : 'contact_name']: values.value
    };

    updateProjectTdsMutation(body);
  };

  const { TableComponent } = useTdsTable({
    tdss: data?.results || [],
    project: data?.meta_data?.project ?? {},
    isLoading: isLoadingFilteredRecords,
    setQueryData,
    invalidateQueryData,
    localSort: true,
    filterColumns: ['company_name'],
    showRfqRow: false,
    extraColumns: [
      {
        title: 'Contact Email',
        key: 'project_contact_email',
        render: (item) => (
          <Typography
            onClick={() => onSelectProjectTds(item, UPDATE_TYPES.CONTACT_EMAIL)}
            className="cursor-pointer"
          >
            {item?.project_contact_email
              ? item?.project_contact_email
              : item?.email ?? '--'}
          </Typography>
        )
      },
      {
        title: 'Contact Name',
        key: 'project_contact_name',
        render: (item) => (
          <Typography
            onClick={() => onSelectProjectTds(item, UPDATE_TYPES.CONTACT_NAME)}
            className="cursor-pointer"
          >
            {item?.project_contact_name ?? '--'}
          </Typography>
        )
      }
    ],
    pagination: false
  });

  return (
    <div>
      {TableComponent}
      <Modal
        title={`Update ${updateType}`}
        open={!!selectedProjectTds}
        onCancel={onCloseModal}
        footer={false}
        closable
      >
        <Form onFinish={onUpdateProjectContact} form={form}>
          <Form.Item
            rules={[
              {
                required: true
              },
              ...(updateType === UPDATE_TYPES.CONTACT_EMAIL
                ? [
                    {
                      type: 'email',
                      message: 'Please enter a valid email'
                    }
                  ]
                : [])
            ]}
            label={updateType}
            name="value"
            layout="vertical"
          >
            <Input />
          </Form.Item>
          <Spacer size={40} />
          <Flex justify="end" className="gap">
            <Button htmlType="button" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button loading={updating} type="primary" htmlType="submit">
              Save
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  );
}

export default ReceiveEmailSupplierTable;
