export const DATETIME_FORMAT = {
  DATE: 'YYYY-MM-DD',
  FULL_DATE: 'MMMM D, YYYY'
};

export const SUPPLIER_CONFIGURATION_STEPS = {
  TESTING: 0,
  COMPLIANCE: 1,
  CONTRACT_TERMS: 2,
  REVIEW_SEND: 3
};
