import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
/* eslint-disable import/no-unresolved */
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
/* eslint-enable import/no-unresolved */

import useLoadingStateClass from 'src/hooks/useLoadingStateClass';
import { sentenceCase, constantCase } from 'change-case';
import { useMarketPriceSeriesCollection } from 'src/Query/marketPrice';
import { Button, Col, Flex, Popover, Row, Typography } from 'antd';
import {
  DownloadOutlined,
  InfoCircleOutlined,
  SettingOutlined
} from '@ant-design/icons';
import useIsConcierge from 'src/hooks/useIsConcierge';
import useRelevantFiltersForForm from 'src/components/project/explore/hooks/useRelevantFiltersForForm';
import { useConciergeContextState } from 'src/components/concierge/ConciergeContext';
import { currencySymbol } from 'src/utils/currency';
import useLineChartSettings from './useLineChartSettings';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  LineElement,
  PointElement
);

const lineChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 5
      },
      border: { display: false },
      grid: { display: false }
    },
    y: {
      position: 'right',
      ticks: {
        maxTicksLimit: 5
      },
      border: {
        dash: [2, 2]
      }
    }
  },
  elements: {
    line: {
      borderWidth: 1
    }
  },
  legend: {
    usePointStyle: true
  },
  plugins: {
    legend: {
      align: 'start',
      pointStyle: 'circle',
      position: 'bottom',
      reverse: false,
      labels: {
        color: '#323640',
        boxHeight: 6,
        boxWidth: 6,
        font: '10',
        padding: 15,
        pointStyle: 'circle',
        usePointStyle: true
      }
    }
  }
};

const dataSkeleton = {
  datasets: [
    {
      data: [
        { x: 0, y: 0 },
        { x: 1, y: 1 }
      ],
      fill: false,
      borderColor: '#eee',
      tension: 0.05
    }
  ]
};

const skeletonColor = '#e8e8e8';

const lineChartOptionsSkeleton = {
  // animations: false,
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    x: {
      type: 'time',
      ticks: {
        maxTicksLimit: 5,
        display: false
      },
      grid: {
        display: false
      },
      border: {
        color: skeletonColor
      }
    },
    y: {
      ticks: {
        maxTicksLimit: 5,
        display: false
      },
      border: {
        color: skeletonColor
      }
    }
  },
  elements: {
    point: {
      pointStyle: false
    },
    line: {
      borderWidth: 1
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const priceMid = (price) =>
  (price && (Number(price.high) + Number(price.low)) / 2) || undefined;

const lineSpecs = [
  {
    type: 'circularPcrResin',
    title: (source) => `Circular`,
    name: 'Circular',
    color: '#2F54EB',
    solid: true,
    filters: { circular: true, form_code__in: ['resin_pellets'] },
    borderWidth: 1.5
  },
  {
    type: 'pcrResin',
    title: (source) => `PCR Resin (${source})`,
    name: 'PCR Resin',
    color: '#bb2E11',
    solid: true,
    filters: { form_code__in: ['resin_pellets'] },
    borderWidth: 1
  },
  {
    type: 'virgin',
    title: (source) => `Virgin (${source})`,
    name: 'Virgin',
    color: '#FA8C16',
    filters: { virgin: true },
    solid: false,
    borderWidth: 1
  },
  {
    type: 'flakes',
    title: (source) => `PCR Flakes (${source})`,
    name: 'PCR Flake',
    color: '#389E0D',
    filters: { form_code__in: ['regrind_flakes'] },
    solid: false,
    borderWidth: 1
  },
  {
    type: 'bales',
    title: (source) => `Bales (${source})`,
    name: 'Bales',
    color: '#EB2F96',
    filters: { form_code__in: ['bales'] },
    solid: false,
    borderWidth: 1
  },
  {
    type: 'tds',
    title: (source) => `Your Price`,
    name: 'Your Price',
    color: '#2F54EB',
    solid: false,
    borderWidth: 1
  },
  {
    type: 'cohort',
    title: (source) => `Circular`,
    name: 'Circular',
    color: '#2F54EB',
    filters: { circular: true, form_code__in: ['resin_pellets'] },
    solid: true,
    borderWidth: 1
  }
];

const formatSource = (name) =>
  name
    ? name.length <= 4
      ? constantCase(name)
      : sentenceCase(name)
    : 'Market';

const isSolid = (solid, defaultValue) => {
  if (solid === undefined) return defaultValue;
  return solid;
};

const generateCsv = (seriesList) => {
  const descendingCurves = seriesList
    .filter((p) => !!p)
    .map((series) => [...series.data.curve].reverse());
  const titleList = seriesList
    .filter((p) => !!p)
    .map((series) =>
      series.title(formatSource(series?.data?.tickers[0]?.source))
    );
  let results = `timestamp,${titleList.join(',')}\n`;
  const maxLength = Math.max(...descendingCurves.map((curve) => curve.length));
  if (maxLength) {
    const longestCurve = descendingCurves.find((p) => p.length === maxLength);
    longestCurve.forEach((point, line) => {
      results += `${point.timestamp},${descendingCurves
        .map((curve) => priceMid(curve[line]) || '')
        .join(',')}\n`;
    });
  }
  return new Blob([results], { type: 'text/csv;charset=utf-8' });
};

export default function MarketPriceByFormLineChart({
  filters,
  title,
  months,
  priceSettings = {},
  onPriceSettingsChange
}) {
  const [localTickerSettings, setLocalTikcerSettings] = useState({});
  const localPriceSettings = useMemo(() => {
    const result = { ...priceSettings };
    Object.keys(localTickerSettings).forEach((key) => {
      if (result[key]) result[key].ticker_id = localTickerSettings[key];
      else result[key] = { ticker_id: localTickerSettings[key] };
    });
    return result;
  }, [priceSettings, localTickerSettings]);

  useEffect(() => {
    if (
      localPriceSettings &&
      Object.keys(localPriceSettings).length &&
      onPriceSettingsChange
    )
      onPriceSettingsChange(localPriceSettings);
  }, [localTickerSettings]);
  const {
    isLoading,
    data: seriesList,
    invalidate
  } = useMarketPriceSeriesCollection(
    lineSpecs,
    filters,
    months,
    localPriceSettings
  );

  const isConcierge = useIsConcierge();

  const loadingStateClass = useLoadingStateClass(isLoading);

  const indices = useMemo(
    () =>
      seriesList?.reduce((acc, curr) => {
        acc[curr.type] = curr?.data.tickers[0]?.market_price_ticker_id;
        return acc;
      }, {}),

    [seriesList]
  );

  const data = useMemo(
    () => ({
      datasets: seriesList
        ?.map((series, index) => {
          if (series) {
            const solid = isSolid(
              localPriceSettings?.[series.type]?.solid,
              series.solid
            );
            return {
              data:
                series.data?.curve?.map((point) => ({
                  x: dayjs(point.timestamp).unix() * 1000,
                  y: priceMid(point)
                })) || [],
              label: series.title(
                formatSource(series?.data?.tickers[0]?.source)
              ),
              circularPriority: solid ? 'A' : 'B',
              fill: localPriceSettings?.[series.type]?.fill || false,
              borderColor:
                localPriceSettings?.[series.type]?.color || series.color,
              borderDash: solid ? undefined : [4, 3],
              borderWidth:
                localPriceSettings?.[series.type]?.width || series.borderWidth,
              tension: 0.05,
              elements: {
                point: {
                  // pointStyle: true,
                  backgroundColor: (ctx) =>
                    solid ? ctx?.element?.options?.borderColor : 'transparent',
                  pointRadius: (ctx) =>
                    series.data?.curve?.length &&
                    ctx.dataIndex === series.data.curve.length - 1
                      ? 2
                      : 0
                }
              }
            };
          }
          return [];
        })
        .filter((o) => o && o.data.length > 0)
        .sort((a, b) => a.circularPriority.localeCompare(b.circularPriority))
    }),
    [seriesList, months]
  );

  const tickerTable = useMemo(
    () => (
      <div>
        {seriesList?.map((series) =>
          series?.data?.tickers?.map((ticker) => (
            <Row
              style={{
                borderTop: 'solid 1px #ddd',
                paddingBottom: 4,
                paddingTop: 4
              }}
              key={series.type}
            >
              <Col style={{ overflowWrap: 'break-word' }} span={4}>
                {series.type}
              </Col>
              <Col style={{ overflowWrap: 'break-word' }} span={4}>
                {ticker.source}
              </Col>
              <Col style={{ overflowWrap: 'break-word' }} span={6}>
                {ticker.source_code}
              </Col>
              <Col style={{ overflowWrap: 'break-word' }} span={10}>
                {ticker.source_name}
              </Col>
            </Row>
          ))
        )}
      </div>
    ),
    [seriesList]
  );
  const download = async () => {
    try {
      const blob = generateCsv(seriesList);
      saveAs(blob, `MarketPriceCurves.csv`);
    } catch (ex) {
      window.console.log('Error converting to CSV', ex);
    }
  };
  // units should be the same for all lines, so we take them from the first
  const weightUnits = seriesList?.[0]?.data?.units || 'lb';
  const currency = seriesList?.[0]?.data?.currency || 'USD';
  const fullUnits = `${currencySymbol(currency)}/${weightUnits}`;
  const fullTitle = title ? `${title} (${fullUnits})` : fullUnits;
  const onSettingsChange = (values) => {
    invalidate();
    setLocalTikcerSettings(values);
  };
  const { ModalComponent: SettingsModal, openModal: openSettings } =
    useLineChartSettings(
      lineSpecs,
      filters,
      indices,
      localPriceSettings,
      onSettingsChange,
      ['tds', 'circularPcrResin']
    );
  return (
    <div className={`market-price-chart__wrapper ${loadingStateClass}`}>
      <Typography.Paragraph className="m-0 light">
        {fullTitle}
      </Typography.Paragraph>
      {SettingsModal}
      <div className="market-price-chart__chart">
        {isLoading ? (
          <Line data={dataSkeleton} options={lineChartOptionsSkeleton} />
        ) : (
          <Line data={data} options={lineChartOptions} />
        )}
      </div>
      {isConcierge && (
        <Flex
          className="market-price-chart__controls"
          gap="middle"
          justify="flex-end"
        >
          <Button type="bare" onClick={download}>
            <DownloadOutlined />
          </Button>
          <Button type="bare" onClick={openSettings}>
            <SettingOutlined />
          </Button>
          <Popover title="Tickers" content={tickerTable}>
            <InfoCircleOutlined />
          </Popover>
        </Flex>
      )}
    </div>
  );
}
MarketPriceByFormLineChart.propTypes = {
  filters: PropTypes.object,
  title: PropTypes.string,
  months: PropTypes.number,
  priceSettings: PropTypes.object
};

export function useLatestRelevantPriceForExplore() {
  const filters = useRelevantFiltersForForm();
  const [projectSettings] = useConciergeContextState([
    'explore',
    'projectSettings'
  ]);

  return useLatestRelevantPriceForFilters(filters, projectSettings?.prices);
}

export function useLatestRelevantPriceForFilters(filters, prices) {
  const { data: curves } = useMarketPriceSeriesCollection(
    lineSpecs,
    filters,
    6,
    prices
  );

  if (!curves) return null;

  const firstValidCurve = curves
    .map(({ data }) => data)
    .find(({ latest }) => !!latest);

  if (!firstValidCurve) return null;

  return {
    label: formatSource(firstValidCurve.tickers[0].source),
    units: firstValidCurve.units,
    currency: firstValidCurve.currency,
    price: priceMid(firstValidCurve.latest)
  };
}
